/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";
import Gtm from "src/components/Gtm";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html lang="nl" />
          <body className="bg-light-blue" aria-busy="true" />
         
          <title>Tampongebruik | Voorlichtingsles</title>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta httpEquiv="x-ua-compatible" content="ie=edge" />
          <meta
            name="description"
            content="Gratis lesmateriaal en werkbladen als aanvulling op lessen seksuele voorlichting."
          />
          <meta
            name="keywords"
            content="seksuele voorlichting, onderwijs, lesmateriaal, lesmateriaal, basisschool, primair onderwijs, middelbare school, voortgezet onderwijs, lesgeven, puberteit, biologie, expertise, werkbladen, seksuele voorlichting groep 8, illustratief materiaal, werkbladen basisschool, leerplan biologie, vakonderwijs. werkbladen primair onderwijs, werkbladen voortgezet onderwijs, multimediaal, online film, kennistoetsen, meisjes, jongens"
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#ffc40d" />
          <meta name="theme-color" content="#ffffff" />
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.11.2/css/all.css"
          />
          <link rel="stylesheet" href="/css/bootstrap.min.css" />
          <link rel="stylesheet" href="/css/mdb.min.css" />
          
          <link href="/system/css/style-min.css" rel="stylesheet" />
          <script type="text/javascript" src="/js/jquery-3.5.1.js" />
          <script type="text/javascript" src="/js/popper.min.js" />
          <script type="text/javascript" src="/js/jquery-migrate-3.3.0.js" />
          <script type="text/javascript" src="/js/bootstrap.min.js" />
          <script type="text/javascript" src="/js/mdb.min.js" />
          <div className="hiddendiv common" />
          <script type="text/javascript" src="/system/js/user.js" />
          <style
            type="text/css"
            cssText="/* Chart.js */
@-webkit-keyframes chartjs-render-animation{from{opacity:0.99}to{opacity:1}}@keyframes chartjs-render-animation{from{opacity:0.99}to{opacity:1}}.chartjs-render-monitor{-webkit-animation:chartjs-render-animation 0.001s;animation:chartjs-render-animation 0.001s;}"
          />
        </Helmet>
        <Gtm />
        ;
      </React.Fragment>
    );
  }
}
