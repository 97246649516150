import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
        <div>
      <div dangerouslySetInnerHTML={{ __html: `

<nav class="navbar navbar-light light-blue lighten-4 d-sm-none">

    <a class="navbar-brand" href="/">
    <img src="/uploads/voorlichtingsles-logo.png" height="40" alt="Voorlichtingsles Volwassen Worden">
  </a>
    
  
  <button class="navbar-toggler toggler-example" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
      <span class="dark-blue-text"><i class="fas fa-bars fa-1x"></i></span>
    </button>

  
  <div class="collapse navbar-collapse" id="navbarSupportedContent1">

    
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link waves-effect waves-light" href="/">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/lessen">Lessen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/lesmaterialen">Lesmaterialen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/multimedia">Multimedia aanpak</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/lesmaterialen/lespakket-gratis-bestellen">Lespakket gratis bestellen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/service">Service</a>
      </li>
    </ul>
    

  </div>
  

</nav>

    <header class="bg-blue d-none d-sm-block pt-3">

        <div class="container-fluid" style="border-bottom:rgb(238,38,110) 7px solid">
            <div class="container">
                <div class="row hideMegaMenu">
                    <div class="col-12">
                        <img src="/uploads/voorlichtingsles-logo.png" alt="Voorlichtingsles Volwassen Worden">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <a class="show-menu" href="/" data-value="-1"><i class="fas fa-home fa-2x"></i></a>
                        <a class="show-menu" href="/lessen" data-value="0">Lessen</a>
                        <a class="show-menu" href="/lesmaterialen" data-value="1">Lesmaterialen</a>
                        <a class="show-menu" href="/multimedia" data-value="2">Multimedia aanpak</a>
                        <a class="show-menu" href="/lesmaterialen/lespakket-gratis-bestellen" data-value="3">Lespakket gratis bestellen</a>
                        <a class="show-menu" href="/service" data-value="4">Service</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="megamenu container-fluid" data-show="0" style="z-index:99999;position:absolute;display:none">
            <div id="megamenu_0" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_1_richtlijnen.jpg" class="img-fluid" alt="Richtlijnen">
                                <a href="/lessen/richtlijnen" class="d-block mt-1 tab-menu stretched-link">Richtlijnen voor de seksuele voorlichting</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_1_lesvoorbereiding.jpg" class="img-fluid" alt="Lesvoorbereiding">
                                <a href="/lessen/lesvoorbereiding" class="d-block mt-1 tab-menu stretched-link">Lesvoorbereiding</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_1_tips.jpg" class="img-fluid" alt="Tips">
                                <a href="/lessen/tips" class="d-block mt-1 tab-menu stretched-link">Tips voor de seksuele voorlichtingslessen</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_1" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-4">
                            <ul class="nav navigatie">
                                <li class="font-weight-bold"><a href="/lesmaterialen/themagebieden" style="color:inherit">Themagebieden</a>
                                    <div>
                                        <a href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit" class="d-block mt-1 tab-menu">Ontwikkeling en puberteit</a>
                                        <a href="/lesmaterialen/themagebieden/geslachtsorganen" class="d-block mt-1 tab-menu">Geslachtsorganen</a>
                                        <a href="/lesmaterialen/themagebieden/cyclus-en-menstruatie" class="d-block mt-1 tab-menu">Menstruatiecyclus</a>
                                        <a href="/lesmaterialen/themagebieden/menstruatie-hygine" class="d-block mt-1 tab-menu">Menstruatie hygi&#xEB;ne</a>
                                        <a href="/lesmaterialen/themagebieden/tampongebruik" class="d-block mt-1 tab-menu">Tampongebruik</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4">
                            <ul class="nav navigatie">
                                <li class="font-weight-bold"><a href="/lesmaterialen/speciale-pakketten" style="color:inherit">Speciale pakketten</a>
                                    <div>
                                        <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-meisjes" class="d-block mt-1 tab-menu">Lesmateriaal voor meisjes</a>
                                        <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="d-block mt-1 tab-menu">Lesmateriaal voor jongens</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4">

                            <ul class="nav navigatie">
                                <li class="font-weight-bold"><a href="/lesmaterialen/speciale-pakketten" style="color:inherit">Lespakket gratis bestellen</a>
                                    <div>
                                        <a href="/lesmaterialen/lespakket-gratis-bestellen/docentenhandleiding" class="d-block mt-1 tab-menu">Docentenhandleiding</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_2" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_3_instructiefilm.jpg" class="img-fluid" alt="Instructiefilm">
                                <a href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden" class="d-block mt-1 tab-menu stretched-link">Instructiefilm over seksuele voorlichting &#xAB;Volwassen worden&#xBB;</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_3_kennistest.jpg" class="img-fluid" alt="Online kennistest">
                                <a href="/multimedia/online-kennistests" class="d-block mt-1 tab-menu stretched-link">Online kennistest</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_3_oefeningen.jpg" class="img-fluid" alt="Oefenbladen">
                                <a href="/multimedia/oefenbladen" class="d-block mt-1 tab-menu stretched-link">Oefenbladen</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_3" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_4_primaironderwijs.jpg" class="img-fluid" alt="Bestel nu gratis">
                                <a href="/lesmaterialen/lespakket-gratis-bestellen" class="d-block mt-1 tab-menu stretched-link">Bestel nu gratis</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_4" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_5_belangrijke_vragen.jpg" class="img-fluid" alt="Belangrijke vragen">
                                <a href="/service/veel-gestelde-vragen" class="d-block mt-1 tab-menu stretched-link">Belangrijke vragen over menstruatie en menstruatiehygi&#xEB;ne</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_5_belangrijke_links.jpg" class="img-fluid" alt="Service">
                                <a href="/service/belangrijke-links" class="d-block mt-1 tab-menu stretched-link">Belangrijke links</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_5_contact.jpg" class="img-fluid" alt="Contact">
                                <a href="/service/contact" class="d-block mt-1 tab-menu stretched-link">Contact</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
        </div>
    </header>
    
    <main class="hideMegaMenu">
        <div class="container-fluid opening mb-3">            
            <div class="container bg-blauw">
                <div class="row">
                    <div class="col-md-6 px-4 py-3">
                        <h1 class="h1-responsive text-white">Tampongebruik</h1>

                    </div>
                    <div class="col-md-6">
                        <img src="/uploads/img_4456_2_lesmateriaal.jpg" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid hideMegaMenu">
            <div class="container">

    <div id="breadcrumb"><div class="bc my-3 d-none d-sm-block ">
    <a href="/">Home</a> &gt; <a href="/lesmaterialen">Lesmaterialen voor seksuele voorlichtingslessen</a> &gt; <a href="/lesmaterialen/themagebieden">Themagebieden</a> &gt; <a href="/lesmaterialen/themagebieden/tampongebruik">Tampongebruik</a>
</div>
</div>

                <div class="row">
                    <div class="col-md-8">
                        <div id="content">
        <h1 class="h1-responsive my-3  ">Tampongebruik</h1>
         <p class="lead ">De voordelen van tampongebruik worden door meisjes meestal snel erkent- ondanks dat bestaat er vaak nog veel onzekerheid over het gebruik.

</p>
        <p></p><p>Behandeling en functionaliteit vereisen uitleg, dus het is des te belangrijker om de meisjes veiligheid te geven en vragen te beantwoorden zoals &quot;kan het koordje niet breken&quot; of kan de tampon in het lichaam verdwijnen?</p>
<p>Het demonstratie set en het bekkenbodemprofiel kunnen in de voorlichtingslessen een waardevolle hulp zijn om de nodige informatie te geven voor het omgaan met tampons. Hier kunt u het o.b.&#xAE; schoolpakket gratis bestellen.</p>

<div class="card">
    <div class="card-body">
        <h5 class="card-title">
            Hier alle documenten bij het themagebied met een klik te downloaden.</h5>
        <a href="/uploads/verzamelingen/lesmateriaal-themagebied-tampongebruik.zip" class="btn bg-blauw text-white waves-effect waves-light">Download</a>
    </div>
</div>

<div class="B00562">
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/structuur-van-de-tampon-4622.png" class="img-fluid" alt="Structuur" van de tampon>
    </div>
    <div class="col-md-6">
        <h5>Structuur van de tampon</h5>
        <p>Horizontaal: samenstelling en structuur van een tampon.</p>
    </div>
    <div class="col-md-3 ">
    <a href="/uploads/structuur-van-de-tampon-4622.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/voorbereiding-4623.png" class="img-fluid" alt="Voorbereiding">
    </div>
    <div class="col-md-6">
        <h5>Voorbereiding</h5>
        <p>Horizontaal: voorbereiden van een tampon. Stp-voor-stap gebruiksafbeeldingen met hygi&#xEB;ne aanwijzingen.</p>
    </div>
    <div class="col-md-3 ">
    <a href="/uploads/voorbereiding-4623.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/inbrengen-en-juiste-plek-4624.png" class="img-fluid" alt="Inbrengen" en juiste plek>
    </div>
    <div class="col-md-6">
        <h5>Inbrengen en juiste plek</h5>
        <p>Horizontaal: lesmateriaal voor het inbrengen van een tampon en de juiste plaatsbepaling.</p>
    </div>
    <div class="col-md-3 ">
    <a href="/uploads/inbrengen-en-juiste-plek-4624.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/faq-tampongebruik-4626.png" class="img-fluid" alt="FAQ" tampongebruik>
    </div>
    <div class="col-md-6">
        <h5>FAQ tampongebruik</h5>
        <p>Achtergrondinformatie/pdf. De meeste gestelde vragen van jonge meisjes over het gebruik van tampons en het voor de leeftijd geschikte antwoord voor de voorlichtingslessen.</p>
    </div>
    <div class="col-md-3 ">
    <a href="/uploads/faq-tampongebruik-4626.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/hoe-wordt-een-tampon-ingebracht-4628.png" class="img-fluid" alt="Hoe" wordt een tampon ingebracht?>
    </div>
    <div class="col-md-6">
        <h5>Hoe wordt een tampon ingebracht?</h5>
        <p>Informatie voor meisjes. Praktische tips voor het inbrengen en wisselen van tampons.</p>
    </div>
    <div class="col-md-3 ">
    <a href="/uploads/hoe-wordt-een-tampon-ingebracht-4628.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/principe-van-een-tampon-4630.png" class="img-fluid" alt="Principe" van een tampon>
    </div>
    <div class="col-md-6">
        <h5>Principe van een tampon</h5>
        <p>Werkingsprincipe en doorlaatbaarheid van een tampon</p>
    </div>
    <div class="col-md-3 ">
    <a href="/uploads/principe-van-een-tampon-4630.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
</div>

<h3>Meer informatie</h3>
<p>
    <a href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit">Ontwikkeling en puberteit</a><br>
    <a href="/lesmaterialen/themagebieden/geslachtsorganen">Geslachtsorganen</a><br>
    <a href="/lesmaterialen/themagebieden/cyclus-en-menstruatie">Cyclus en menstruatie</a><br>
    <a href="/lesmaterialen/themagebieden/menstruatie-hygine">Menstruatie hygi&#xEB;ne</a><br>
    <a href="/service/veel-gestelde-vragen">Veel voorkomende vragen over de menstruatie en de menstruatie hygi&#xEB;ne</a>
</p><p></p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="bg-blue zij-menu p-3">
    <a href="/lesmaterialen" class="level-1 d-block ">Lesmaterialen voor seksuele voorlichtingslessen</a>
<a href="/lesmaterialen/themagebieden" class="level-2 d-block ">Themagebieden</a>
<a href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit" class="level-3 d-block ">Ontwikkeling en puberteit</a>
<a href="/lesmaterialen/themagebieden/geslachtsorganen" class="level-3 d-block ">Geslachtsorganen</a>
<a href="/lesmaterialen/themagebieden/cyclus-en-menstruatie" class="level-3 d-block ">Cyclus en menstruatie</a>
<a href="/lesmaterialen/themagebieden/menstruatie-hygine" class="level-3 d-block ">Menstruatie hygi&#xEB;ne</a>
<a href="/lesmaterialen/themagebieden/tampongebruik" class="level-3 d-block text-rood">Tampongebruik</a>
<a href="/lesmaterialen/speciale-pakketten" class="level-2 d-block ">Speciale pakketten</a>
<a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-meisjes" class="level-3 d-block ">Lesmateriaal voor meisjes</a>
<a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="level-3 d-block ">Lesmateriaal voor jongens</a>

<a href="/lesmaterialen/lespakket-gratis-bestellen" class="level-2 d-block ">Lespakket gratis bestellen</a>
<a href="/lesmaterialen/lespakket-gratis-bestellen/docentenhandleiding" class="level-3 d-block ">Docentenhandleiding</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </main>

    
<div class="B00544">
    <div class="container-fluid pb-3">
        <div class="container">
            <div class="row mr-md-5 pr-md-5">
        <div class="col-md-4 text-center d-none">
    <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="stretched-link"><img src="/uploads/callout1_jongen.png" class="img-fluid p-3" alt="Materiaal voor jongens"></a>
    <h3>Materiaal voor jongens</h3>
    <div class="pay-off">
        Lesmateriaal rondom het thema puberteit bij jongens
    </div>
    <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu downloaden</a>
</div>
<div class="col-md-4 text-center d-none">
    <a href="/lesmaterialen/speciale-pakketten" class="stretched-link"><img src="/uploads/callout2_primair_onderwijs.png" class="img-fluid p-3" alt="Materiaal voor basisscholen"></a>
    <h3>Materiaal voor basisscholen</h3>
    <div class="pay-off">
        Lesmateriaal voor seksuele voorlichtingslessen
    </div>
    <a href="/lesmaterialen/speciale-pakketten" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu samenstellen</a>
</div>
<div class="col-md-4 text-center ">
    <a href="/lessen/lesvoorbereiding" class="stretched-link"><img src="/uploads/callout3_lesvoorbereiding.png" class="img-fluid p-3" alt="Hulp bij de lesvoorbereiding"></a>
    <h3>Hulp bij de lesvoorbereiding</h3>
    <div class="pay-off">
        Tips en checklists voor seksuele voorlichtingslessen
    </div>
    <a href="/lessen/lesvoorbereiding" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu informeren</a>
</div>
<div class="col-md-4 text-center d-none">
    <a href="/lesmaterialen" class="stretched-link"><img src="/uploads/callout5_lesmateriaal.png" class="img-fluid p-3" alt="Download lesmaterialen"></a>
    <h3>Download lesmaterialen</h3>
    <div class="pay-off">
        Uitgebreid aanbod van onderwerpen voor de seksuele voorlichting 
    </div>
    <a href="/lesmaterialen" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Naar de materialen</a>
</div>
<div class="col-md-4 text-center ">
    <a href="/multimedia" class="stretched-link"><img src="/uploads/callout6_multimedia.png" class="img-fluid p-3" alt="Multimedia voor seksuele voorlichting"></a>
    <h3>Multimedia voor seksuele voorlichting</h3>
    <div class="pay-off">
        Instructiefilm en interactieve kennistests voor seksuele voorlichting
    </div>
    <a href="/multimedia" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu bekijken</a>
</div>
<div class="col-md-4 text-center ">
    <a href="/lesmaterialen/lespakket-gratis-bestellen" class="stretched-link"><img src="/uploads/callout4_lespakket_bestellen.png" class="img-fluid p-3" alt="Gratis lespakket"></a>
    <h3>Gratis lespakket</h3>
    <div class="pay-off">
        Relevante lesmaterialen voor seksuele voorlichting
    </div>
    <a href="/lesmaterialen/lespakket-gratis-bestellen" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu bestellen</a>
</div>
            </div>
        </div>
    </div>

</div>        
              
        ` }} />
           <Footer></Footer>
        </div>  
       
    );
  }
}

export default Page;


